<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="close()" max-width="850px" @keydown.esc="close()">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text class="modal-content">
            <div class="section-container">
              <div class="row">
                <div class="col-md-3" v-if="property_sale.id > 0">
                  <v-text-field :disabled="true" label="Carpeta" name="id" v-model="property_sale.id"></v-text-field>
                </div>
                <div :class="property_sale.id > 0 ? 'col-md-3' : 'col-md-4'">
                  <DatePickerEsp
                    :initToday="true"
                    label="Fecha de apertura"
                    :date="property_sale.open_date"
                    :isRequired="true"
                    name="open_date"
                    :isViewModal="isViewModal()"
                    @setDate="
                      (date) => {
                        property_sale.open_date = date;
                      }
                    "
                  />
                  <span class="red--text">{{ errors.first("open_date") }}</span>
                </div>

                <div :class="property_sale.id > 0 ? 'col-md-3' : 'col-md-4'">
                  <v-combobox
                    data-vv-as="notary"
                    label="Escribano*"
                    name="notary"
                    v-model="property_sale.notary"
                    :items="employees_notary"
                    item-text="name"
                    item-value="id"
                    v-validate="'required'"
                    :disabled="isViewModal()"
                    :loading="loading_notary"
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("notary") }}</span>
                </div>
                <div :class="property_sale.id > 0 ? 'col-md-3' : 'col-md-4'">
                  <v-combobox
                    data-vv-as="folder_manager"
                    label="Carpetista*"
                    name="folder_manager"
                    v-model="property_sale.folder_manager"
                    :items="employees_employee"
                    item-text="name"
                    item-value="id"
                    v-validate="'required'"
                    :disabled="isViewModal()"
                    :loading="loading_folder_manager"
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("folder_manager") }}</span>
                </div>

                <div class="col-md-4">
                  <v-combobox
                    data-vv-as="prepare"
                    label="Preparo"
                    name="prepare"
                    v-model="property_sale.prepare"
                    :items="employees"
                    item-text="name"
                    item-value="id"
                    :disabled="isViewModal()"
                    :loading="loading_employee"
                  ></v-combobox>
                </div>
                <div class="col-md-4">
                  <v-combobox
                    data-vv-as="realize"
                    label="Reviso"
                    name="realize"
                    v-model="property_sale.realize"
                    :items="employees"
                    item-text="name"
                    item-value="id"
                    :disabled="isViewModal()"
                    :loading="loading_employee"
                  ></v-combobox>
                </div>
                <div class="col-md-4">
                  <v-combobox
                    data-vv-as="armario"
                    label="Armario"
                    name="cupboard_row"
                    v-model="property_sale.cupboard_row"
                    :items="cupboards_rows"
                    item-text="name"
                    item-value="id"
                    :disabled="isViewModal()"
                    :loading="loading_cupboard"
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("cupboard_row") }}</span>
                </div>
                <div class="col-md-6">
                  <div class="d-flex align-center">
                    <v-combobox
                      class="flex-grow-1"
                      data-vv-as="compradores"
                      label="Seleccione los compradores*"
                      name="property_buyers"
                      v-model="property_sale.property_buyers"
                      :items="clients"
                      item-text="business_name"
                      item-value="id"
                      v-validate="'required'"
                      :disabled="isViewModal()"
                      multiple
                      :loading="loading_client"
                      chips
                      small-chips
                    ></v-combobox>
                    <v-tooltip bottom v-if="!isViewModal()">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="blue" class="ml-2" @click="addClient('buyers')" style="border: 1px solid #2196f3; border-radius: 50%; padding: 5px"> mdi-plus </v-icon>
                      </template>
                      <span>Crear cliente</span>
                    </v-tooltip>
                  </div>

                  <span class="red--text">{{ errors.first("property_buyers") }}</span>
                </div>

                <div class="col-md-6">
                  <div class="d-flex align-center">
                    <v-combobox
                      data-vv-as="vendedores"
                      label="Seleccione los vendedores*"
                      name="property_sellers"
                      v-model="property_sale.property_sellers"
                      :items="clients"
                      item-text="business_name"
                      item-value="id"
                      v-validate="'required'"
                      :disabled="isViewModal()"
                      multiple
                      :loading="loading_client"
                      chips
                      small-chips
                    ></v-combobox>

                    <v-tooltip bottom v-if="!isViewModal()">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="blue" class="ml-2" @click="addClient('sellers')" style="border: 1px solid #2196f3; border-radius: 50%; padding: 5px"> mdi-plus </v-icon>
                      </template>
                      <span>Crear cliente</span>
                    </v-tooltip>
                  </div>

                  <span class="red--text">{{ errors.first("property_sellers") }}</span>
                </div>
                <div class="col-md-9">
                  <v-text-field data-vv-as="direccion" label="Dirección del inmueble*" name="address" v-model="property_sale.address" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                  <span class="red--text">{{ errors.first("address") }}</span>
                </div>
                <div class="col-md-3">
                  <v-text-field
                    data-vv-as="importe"
                    label="Importe*"
                    name="amount"
                    type="number"
                    v-model="property_sale.amount"
                    v-validate="'required|decimal:2'"
                    :disabled="isViewModal()"
                  ></v-text-field>
                  <span class="red--text">{{ errors.first("amount") }}</span>
                </div>
                <div class="col-md-12">
                  <v-textarea data-vv-as="observacion" label="Observación" name="observation" v-model="property_sale.observation" :disabled="isViewModal()"></v-textarea>
                  <span class="red--text">{{ errors.first("observation") }}</span>
                </div>
              </div>
            </div>
            <div class="section-container">
              <div class="row">
                <div class="col-md-12">
                  <p class="title-modal">Firma</p>
                  <hr />
                </div>
                <div class="col-md-3">
                  <DatePickerEsp
                    data-vv-as="fecha"
                    label="Fecha"
                    :date="property_sale.signature_date"
                    :isRequired="true"
                    name="signature_date"
                    :isViewModal="isViewModal()"
                    @setDate="
                      (date) => {
                        property_sale.signature_date = date;
                      }
                    "
                  />
                </div>

                <div class="col-md-3">
                  <v-menu
                    ref="menu"
                    v-model="menu_time"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="property_sale.signature_time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="property_sale.signature_time"
                        label="Hora de inicio"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        name="time"
                        v-on="on"
                        :disabled="isViewModal()"
                      ></v-text-field>
                    </template>
                    <v-time-picker v-if="menu_time" v-model="property_sale.signature_time" full-width @click:minute="$refs.menu.save(property_sale.signature_time)"></v-time-picker>
                  </v-menu>
                </div>

                <div class="col-md-6">
                  <v-text-field data-vv-as="lugar de firma" label="Lugar de firma" name="signature_location" v-model="property_sale.signature_location" :disabled="isViewModal()"></v-text-field>
                </div>
                <div class="col-md-12 d-flex justify-content-end">
                  <v-btn color="primary" small :disabled="property_sale.signature_time == '' || property_sale.signature_date == ''" @click="$emit('calendar', property_sale)">Agendar evento</v-btn>
                </div>
              </div>
            </div>
            <div class="section-container">
              <div class="row">
                <div class="col-md-12">
                  <p class="title-modal">Escriturado</p>
                  <hr />
                </div>
                <div class="col-md-12">
                  <v-label>Venta</v-label>
                </div>
                <div class="col-md-6">
                  <v-text-field data-vv-as="folio" label="Folio" name="written_sale_folio" v-model="property_sale.written_sale_folio" :disabled="isViewModal()"></v-text-field>
                  <span class="red--text">{{ errors.first("written_sale_folio") }}</span>
                </div>
                <div class="col-md-6">
                  <v-text-field data-vv-as="numero" label="Número" name="written_sale_numero" v-model="property_sale.written_sale_numero" :disabled="isViewModal()"></v-text-field>
                  <span class="red--text">{{ errors.first("written_sale_numero") }}</span>
                </div>
                <div class="col-md-12">
                  <v-label>Hipoteca</v-label>
                </div>
                <div class="col-md-6">
                  <v-text-field data-vv-as="folio" label="Folio" name="written_mortage_folio" v-model="property_sale.written_mortage_folio" :disabled="isViewModal()"></v-text-field>
                  <span class="red--text">{{ errors.first("written_mortage_folio") }}</span>
                </div>
                <div class="col-md-6">
                  <v-text-field data-vv-as="numero" label="Número" name="written_mortage_numero" v-model="property_sale.written_mortage_numero" :disabled="isViewModal()"></v-text-field>
                  <span class="red--text">{{ errors.first("written_mortage_numero") }}</span>
                </div>
              </div>
            </div>

            <div class="section-container">
              <div class="col-md-12">
                <p class="title-modal">Documentacion</p>
                <hr />
                <ListPropertyDocumentation :list="property_sale.property_documents" :loading="loading_documentation" @updateDocument="updateDocument" :isViewModal="isViewModal()" />
              </div>
            </div>

            <div class="section-container">
              <div class="col-md-12">
                <p class="title-modal">Liberacion</p>
                <hr />
              </div>
              <ListPropertyReleases :list="property_sale.property_releases" :loading="loading_releases" @updateRelease="updateRelease" :isViewModal="isViewModal()" />
            </div>
          </v-card-text>

          <v-card-actions style="background-color: rgb(221, 225, 230)">
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="close()">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>

      <ModalClient
        :modal_title="modal_title_client"
        :formBtn="formBtn"
        :dialog="dialog_client"
        :parent="parent"
        :service="generalService"
        @updateDialog="updateDialogClient"
        @loadList="loadClients(true, type_add)"
        :item="mainItemClient"
        @updateItem="
          (newItem) => {
            mainItemClient = newItem;
          }
        "
        :section="Constant.SECTION_CLIENTS"
      />
    </v-dialog>
  </v-layout>
</template>

<script>
import { generalService } from "@/libs/ws/generalService";
import ConstantEmployeeTypes from "@/constants/employee_types.js";
import Constant from "@/constants/sections";
import DatePickerEsp from "@/components/DatePickerEsp.vue";
import ListPropertyDocumentation from "./ListPropertyDocumentation.vue";
import ListPropertyReleases from "./ListPropertyReleases.vue";
import { fileUtils } from "@/libs/utils/fileUtils";
import { PDFDocument } from "pdf-lib";
import ModalClient from "../Clients/Modal.vue";

export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
    updateMainItem: Function,
    updateItem: Function,
    calendar: Function,
  },
  components: { DatePickerEsp, ListPropertyDocumentation, ListPropertyReleases, ModalClient },

  data() {
    return {
      show: false,
      loading: false,
      generalService: generalService,
      Constant: Constant,
      employees_employee: [],
      employees_notary: [],
      cupboardRows: [],
      employees: [],
      clients: [],
      cupboards: [],
      cupboards_rows: [],
      loading_documentation: false,
      loading_releases: false,
      menu_time: false,
      dialog_client: false,
      loading_client: false,
      loading_notary: false,
      loading_folder_manager: false,
      loading_employee: false,
      loading_cupboard: false,
      modal_title_client: "Cliente",
      type_add: "",
      mainItemClient: {
        id: "",
        business_name: "",
        code: "",
        cuit: "",
        phone: "",
        address: "",
        custom_percentage: null,
      },
      defaultItemClient: null,
      property_sale: {
        id: 0,
        open_date: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        notary: null,
        folder_manager: null,
        prepare: null,
        realize: null,
        cupboard_row: null,
        property_buyers: [],
        property_sellers: [],
        property_documents: [],
        property_releases: [],
        address: "",
        amount: "",
        signature_date: "",
        signature_time: "",
        signature_location: "",
        written_sale_folio: "",
        written_sale_numero: "",
        written_mortage_folio: "",
        written_mortage_numero: "",
        observation: "",
      },
      defaultPropertySale: null,
    };
  },
  watch: {
    item() {
      // this.$emit("updateItem", this.item);
    },
    dialog(newValue) {
      this.errors.clear();

      if (newValue) {
        if (this.item && this.item.id != "") {
          this.property_sale = JSON.parse(JSON.stringify(this.item));
        } else {
          (this.property_sale.open_date = new Date(
            new Date().toLocaleDateString("en-US", {
              timeZone: "America/Buenos_Aires",
            })
          )
            .toISOString()
            .slice(0, 10)),
            this.loadDocumentation();
          this.loadReleases();
        }
      }
    },
  },
  mounted() {
    this.defaultPropertySale = JSON.parse(JSON.stringify(this.property_sale));
    this.loadCupboards();
    this.loadComboData();
    this.loadClients();
  },
  methods: {
    addClient(type) {
      this.type_add = type;
      this.mainItemClient = {
        business_name: "",
        code: "",
        cuit: "",
        phone: "",
        address: "",
        custom_percentage: null,
      };
      this.dialog_client = true;
    },
    updateDialogClient(value) {
      this.dialog_client = value;
    },

    close() {
      this.property_sale = JSON.parse(JSON.stringify(this.defaultPropertySale));
      this.$emit("updateMainItem");
      this.$emit("updateDialog", false);
    },
    updateRelease(updatedRelease) {
      const index = this.property_sale.property_releases.findIndex((release) => release.release_type.id === updatedRelease.release_type.id);
      if (index !== -1) {
        this.property_sale.property_releases.splice(index, 1, updatedRelease);
      }
    },
    updateDocument(updatedDoc) {
      const index = this.property_sale.property_documents.findIndex((doc) => doc.documentation_type.id === updatedDoc.documentation_type.id);

      if (index !== -1) {
        this.property_sale.property_documents.splice(index, 1, updatedDoc);
      }
    },
    async updateAttachedFiles() {
      for (let i = 0; i < this.property_sale.property_documents.length; i++) {
        const doc = this.property_sale.property_documents[i];
        for (let j = 0; j < doc.property_documentation_files.length; j++) {
          const file = doc.property_documentation_files[j];
          if (!(file instanceof File)) {
            continue;
          }

          if (!this.property_sale.property_documents[i].property_documentation_files[j]) {
            this.property_sale.property_documents[i].property_documentation_files[j] = {
              id: null,
              name: "",
              file: null,
              pages: 0,
            };
          }

          this.property_sale.property_documents[i].property_documentation_files[j].filename = file.name;

          this.getPagePdf(file, j, i);
          const b64file = await fileUtils.toBase64(file);

          this.property_sale.property_documents[i].property_documentation_files[j].file = b64file;
        }
      }
    },
    async getPagePdf(file, index, indexPropertyDocumentation) {
      const currentComponent = this;
      var fileReader = new FileReader();
      fileReader.onload = async function () {
        var typedarray = new Uint8Array(this.result);
        const pdfDoc = await PDFDocument.load(typedarray);
        const pages = pdfDoc.getPages();
        currentComponent.property_sale.property_documents[indexPropertyDocumentation].property_documentation_files[index].pages = Math.ceil(pages.length / 2); //doblefaz
      };
      fileReader.readAsArrayBuffer(file);
    },
    loadCupboards() {
      this.loading_cupboard = true;

      generalService
        .filter({}, Constant.SECTION_CUPBOARD)
        .then((response) => {
          this.cupboards = response.result;
          this.cupboards_rows = response.result.flatMap((cupboard) =>
            cupboard.rows.map((row) => ({
              id: row.id,
              name: row.name,
              cupboard: row.cupboard,
            }))
          );
        })
        .catch((response) => {
          console.log("error", response);
        })
        .finally(() => {
          this.loading_cupboard = false;
        });
    },

    loadDocumentation() {
      this.loading_documentation = true;

      this.service
        .getPropertyDocuments()
        .then((response) => {
          this.property_sale.property_documents = response.result;
        })
        .catch((response) => {
          console.log("error", response);
        })
        .finally(() => {
          this.loading_documentation = false;
        });
    },
    loadReleases() {
      this.loading_releases = true;
      this.service
        .getPropertyReleases()
        .then((response) => {
          this.property_sale.property_releases = response.result;
        })
        .catch((response) => {
          console.log("error", response);
        })
        .finally(() => {
          this.loading_releases = false;
        });
    },

    loadClients(loadClient = false, type) {
      this.loading_client = true;
      generalService.filter({}, Constant.SECTION_CLIENTS).then((response) => {
        this.clients = response.result;

        if (loadClient) {
          const newClient = this.clients.reduce((max, client) => (client.id > max.id ? client : max));
          if (type == "buyers") {
            this.property_sale.property_buyers.push(newClient);
            this.loading_client = false;
          } else {
            this.property_sale.property_sellers.push(newClient);
            this.loading_client = false;
          }
        } else {
          this.loading_client = false;
        }
      });
    },
    loadComboData() {
      this.loading_folder_manager = true;
      this.loading_notary = true;
      this.loading_employee = true;
      generalService.filter({ employee_type: ConstantEmployeeTypes.TYPE_EMPLOYEE }, Constant.SECTION_EMPLOYEES).then((response) => {
        this.employees_employee = response.result;
        this.loading_folder_manager = false;
      });

      generalService.filter({ employee_type: ConstantEmployeeTypes.TYPE_NOTARY }, Constant.SECTION_EMPLOYEES).then((response) => {
        this.employees_notary = response.result;
        this.loading_notary = false;
      });

      generalService.filter({}, Constant.SECTION_EMPLOYEES).then((response) => {
        this.employees = response.result;
        this.loading_employee = false;
      });
    },
    isViewModal() {
      return this.formBtn == "";
    },
    createItem(e) {
      this.loading = true;
      this.$validator.validate().then(async (valid) => {
        if (valid) {
          e.preventDefault();
          await this.updateAttachedFiles();
          let data = JSON.parse(JSON.stringify(this.property_sale));

          for (let i = 0; i < data.property_documents.length; i++) {
            const doc = data.property_documents[i];
            doc.property_documentation_files_arr = doc.property_documentation_files;

            delete doc.property_documentation_files;
          }

          data.property_sellers_arr = data.property_sellers;
          data.property_buyers_arr = data.property_buyers;
          data.property_documents_arr = data.property_documents;
          data.property_releases_arr = data.property_releases;

          delete data.property_sellers;
          delete data.property_buyers;
          delete data.property_documents;
          delete data.property_releases;

          if (data.id > 0) {
            this.service
              .update(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.close();
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => {
                this.loading = false;
                this.close();
              });
          } else {
            this.service
              .create(data)
              .then((response) => {
                console.log(response);
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.close();
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else {
          console.log(this.errors);
          let msg = "";
          this.errors.items.forEach((e) => {
            msg += e.msg + "\n";
          });
          this.$toast.error(msg, "Error");
        }
      });
    },
  },
};
</script>
