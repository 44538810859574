import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.loading,"loading-text":"Cargando...","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.release_type.name))]),_c('td',[(props.item.done === true)?_c(VIcon,{attrs:{"small":"","color":"green"}},[_vm._v("check")]):_vm._e(),(props.item.done === false)?_c(VIcon,{attrs:{"small":"","color":"red"}},[_vm._v("close")]):_vm._e()],1),_c('td',[_vm._v(_vm._s(props.item.done_date))]),_c('td',{staticClass:"text-center"},[(!_vm.isViewModal)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.editItem(props.item)}}},on),[_vm._v(" edit ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]):_vm._e()],1)])]}}])}),_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.dialog_edit),callback:function ($$v) {_vm.dialog_edit=$$v},expression:"dialog_edit"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.editedItem.release_type.name))])]),_c(VCardText,[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c(VCheckbox,{attrs:{"label":"Realizo"},model:{value:(_vm.editedItem.done),callback:function ($$v) {_vm.$set(_vm.editedItem, "done", $$v)},expression:"editedItem.done"}})],1),_c('div',{staticClass:"col-md-6"},[_c('DatePickerEsp',{staticClass:"pt-0",attrs:{"label":"Fecha","date":_vm.editedItem.done_date,"name":"done_date","initToday":true},on:{"setDate":function (date) {
                  _vm.editedItem.done_date = date;
                }}})],1)])]),_c(VCardActions,{staticClass:"justify-end"},[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":_vm.closeEdit}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"success","text":""},on:{"click":_vm.saveEdit}},[_vm._v("Guardar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }